import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChartModel } from '../..';

const ChartWithSelector = (props) => {
  const [chartData, setChartData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(props.options[0]);
  const [color, setColor] = useState('rgba(255, 0, 0)');

  useEffect(() => {
    requestChartData(selectedOption);
  }, []); 

  const handleOptionChange = async (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    requestChartData(newSelectedOption);
  };

  const requestChartData = async (option) => {
    try {
      const foundItem = props.list.find(item => item.label === option);
      const response = await axios.get(`${props.linkGraficos}${foundItem.id}.json`);
      setColor(Array(response.data[0].length).fill(foundItem.rgba))
      setChartData(response.data);
    } catch (error) {
      console.error('Erro ao obter dados do gráfico:', error);
    }
  };

  return (
    <div>
      Classe:
      <select className="listOfModes" value={selectedOption} onChange={handleOptionChange}>
        {props.options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
      {chartData ? (
        <ChartModel
          mode="bar"
          labels={chartData[0]}
          datas={chartData[1]}
          colors={color}
          responsive={true}
          legend={false}
          orientation={"x"}
        />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default ChartWithSelector;