/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */


import React from "react";
import { desativarLayer } from "..";

export function toggleLegendFilter(event) {
    event.persist()
    const parentDiv = event.target.parentNode.parentNode.parentNode.parentNode;
    parentDiv.querySelectorAll('.legendRight').forEach(element => {
        element.querySelectorAll('.legendaChart').forEach(legenda => {
            legenda.querySelectorAll('.changeTextColor').forEach(container => {
                let comparator;
                if (container.style.color === "rgb(90, 90, 90)") { comparator = true }
                else { comparator = false }
                if (comparator !== event.target.checked) { container.click() }
            });
        });
    });
}

export function toggleLegendFilterByClass(event, classe) {
    event.persist()
    document.querySelectorAll(`.${classe}`).forEach(element => {
        element.querySelectorAll('.legendaChart').forEach(legenda => {
            legenda.querySelectorAll('.changeTextColor').forEach(container => {
                let comparator;
                if (container.style.color === "rgb(90, 90, 90)") { comparator = true }
                else { comparator = false }
                if (comparator !== event.target.checked) { container.click() }
            });
        });
    });
}

export function LegendChart(props) {

    const textoLegenda = (titulo, id, mode) => {
        const changeText = document.getElementsByClassName("changeTextColor")
        for (let i = 0; i < changeText.length; i++) {
            if (changeText[i].id === id + titulo) {
                var trocarTexto = changeText[i]
                var trocarCor = document.getElementsByClassName("trocarCor")[i]
                var tampa = document.getElementsByClassName("tampa")[i]

                if (trocarTexto.style.color == "rgb(90, 90, 90)") {
                    trocarCor.style.opacity = "1"
                    trocarTexto.style.color = "#929292";
                    tampa.style.opacity = "0";
                } else {
                    trocarTexto.style.color = "rgb(90, 90, 90)"
                    trocarCor.style.opacity = "0"
                    tampa.style.opacity = "1";
                }
            }
        }
        desativarLayer(titulo, mode)
    }

    return (
        <div id="leg" className={`legendaChart ${props.class}`} key={props.keyProp}>
            {props.objectLegend.map((settings) => (
                <div role="button" tabIndex={0}
                    onClick={() => textoLegenda(settings.titulo, settings.id, settings.mode)}
                    className="changeTextColor" key={settings.id}
                    id={settings.id + settings.titulo}
                    style={{ color: "#5a5a5a" }}>
                    <div className={`tampa ${props.mode}`} style={{ background: settings.cor }}></div>
                    <input disabled className={`trocarCor ${props.mode}`} style={{ border: 'solid 3px ' + settings.cor }} ></input>
                    {settings.titulo}
                </div>
            ))}
        </div>
    )
}
