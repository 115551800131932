import React, { useState } from "react";
// eslint-disable-next-line import/no-named-as-default
import DeckGL from "deck.gl";
import { _MapContext as MapContext, StaticMap, Popup } from "react-map-gl";
import { addSelectedGeometry, getApiimagens, getList, RefreshMap, ResetJsonData } from "../index";
import { getZoomMap, setLat, setLng, setZoomMap } from "../DataHandler/DataHandler.jsx";
import { PopupInfo } from "./PopupInfo.jsx";
import { RightButton } from "../Funcionalidades/RightButton/RightButton.jsx";
import ExpandedImage from "./ExpandedImage.jsx";
import axios from "axios";

var initialViewState = {
    longitude: -46.6333,
    latitude: -23.6900,
    zIndex: 0,
    zoom: 9,
    maxZoom: 21,
    pitch: 0
};

export function setCoordinates(lat, long, zoom) {
    initialViewState = {
        longitude: long,
        latitude: lat,
        zIndex: 0,
        zoom: zoom,
        maxZoom: 21,
        pitch: 0
    };
}

var selectEnable = false
var selectPolylines = []
var popupMode, fileNameMaster;
const MAPBOX_ACCESS_TOKEN =
    "token aleatório";
// "pk.eyJ1IjoiZ2Vvcmdpb3MtdWJlciIsImEiOiJjanZidTZzczAwajMxNGVwOGZrd2E5NG90In0.gdsRu_UeU_uPi9IulBruXA"

export function changeSelectGeo() {
    selectEnable = false
}

export function changeViewState(long, lat, time, zoom, pitch, bearing) {
    initialViewState = {
        longitude: long,
        latitude: lat,
        transitionDuration: time,
        zIndex: 0,
        zoom: zoom,
        maxZoom: 21,
        pitch: pitch,
        bearing: bearing
    };
}

export function DeckComponent(props) {
    const [selected, setSelected] = React.useState(null);
    const [images, setImages] = useState([])
    const [blockPopup, setBlockPopup] = useState(false)

    const handleCloseClick = () => {
        setBlockPopup(false)
    };

    function sleepSync(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    return (
        <>
            <DeckGL
                glOptions={{ preserveDrawingBuffer: true }}
                initialViewState={initialViewState}
                layers={props.states}
                controller={true}
                ContextProvider={MapContext.Provider}
                getCursor={() => "pointer"}
                onHover={(info) => {
                    try {
                        if (blockPopup !== true) {
                            if (info.viewport !== undefined) {
                                setLat(info.viewport.latitude)
                                setLng(info.viewport.longitude)
                                setZoomMap(info.viewport.zoom)
                            }
                            if (info.index <= 0 && info.object === undefined) {
                                setSelected(null)
                                RefreshMap()
                                fileNameMaster = undefined
                            } else if (info.object.properties.noPopup) {
                                setSelected(null)
                                RefreshMap()
                            } else if (info.object.properties.nome) {
                                popupMode = "nome"
                                setSelected(info)
                                RefreshMap()
                            }
                            if (info.object !== undefined) {
                                if (info.object.properties.file_name) {
                                    if (getZoomMap() > 15) {
                                        if (fileNameMaster !== info.object.properties.file_name) {
                                            // setImages([])
                                            // setSelected(info);
                                            // const classes = getList()
                                            //     .filter(item => info.object.properties.deteccao_ativa === JSON.parse(item.split(" - ")[1]))
                                            //     .map(item => item.split(" - ")[0])
                                            //     .join(',');
                                            // axios.get(`${getApiimagens()[0]}${getApiimagens()[1]}${info.object.properties.file_name}${getApiimagens()[2]}${classes}`).then((response) => {
                                            //     setImages(response.data)
                                            // })
                                            // fileNameMaster = info.object.properties.file_name
                                            // sleepSync(100);
                                        }
                                    }
                                }
                            }
                        }
                    } catch (error) { console.log(error) }
                }}
                onClick={(info, event) => {
                    setImages([])
                    setSelected(null);
                    if (info.object) {
                        if (info.object.properties.logradouro) {
                            popupMode = "logradouro"
                            setSelected(info)
                            RefreshMap()
                            setBlockPopup(false)
                        }
                        if (info.object.properties.file_name) {
                            if (getZoomMap() > 15) {
                                setSelected(info);
                                if (fileNameMaster !== info.object.properties.file_name) {
                                    const classes = getList()
                                        .filter(item => info.object.properties.deteccao_ativa === JSON.parse(item.split(" - ")[1]))
                                        .map(item => item.split(" - ")[0])
                                        .join(',');
                                    console.log('classes', classes)
                                    axios.get(`${getApiimagens()[0]}${getApiimagens()[1]}${info.object.properties.file_name}${getApiimagens()[2]}${classes}`).then((response) => {
                                        setImages(response.data)
                                    })
                                    fileNameMaster = info.object.properties.file_name
                                    sleepSync(100);
                                }
                                setBlockPopup(true)
                            }
                        }
                        if (info.object.properties.analise) {
                            setSelected(info);
                            setBlockPopup(true)
                        }
                    } else {
                        setBlockPopup(false)
                        if (!selectEnable) {
                            if (info.index <= 0 && info.object === undefined) {
                                ResetJsonData()
                                RefreshMap()
                            }
                        }
                        if (info.object) {
                            setSelected(null);
                            if (event.leftButton) {
                                if (selectEnable) {
                                    addSelectedGeometry(info.object)
                                    // Precisa criar função que sobrepõe as geometrias selecionadas
                                    selectPolylines.push(info.object);
                                    // console.log(selectPolylines);
                                } else {
                                    // setSelected(info);
                                }
                                document.getElementById("bordaCirculo").style.left = "-7000px";
                            } else if (event.rightButton) {
                                setSelected(null);
                                RightButton(event.center.x, event.center.y);
                            }
                        } else {
                            setSelected(null);
                            document.getElementById("bordaCirculo").style.left = "-7000px";
                        }
                    }
                }}
            >
                <>
                    <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
                    {selected && (
                        <Popup
                            longitude={selected.coordinate[0]}
                            latitude={selected.coordinate[1]}
                            closeButton={true}
                            onClose={handleCloseClick}
                            anchor="bottom"
                        >
                            <PopupInfo images={images} selected={selected} popupMode={popupMode} />
                        </Popup>
                    )}
                </>
            </DeckGL>
            <ExpandedImage images={images} selected={selected} />
        </>
    )
}
