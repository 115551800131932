import React from "react";
import { StartSidebar } from "sidebargeovista";
import { changeMonitoramentoHeight, disableAllModes, updateChartHeight } from "..";

export function startSplashScreen() {
    document.querySelector('#fundoSplashScreen').classList.add("active")
    document.querySelector('.splash').classList.add("start")
    detectionsFunc('roadDetections')
    setTimeout(() => {
        if (document.querySelector('#fundoSplashScreen').style.display !== 'none') {
            detectionsFunc('roadDetections')
        }
        setTimeout(() => {
            if (document.querySelector('#fundoSplashScreen').style.display !== 'none') {
                detectionsFunc('roadDetections')
            }
            setTimeout(() => {
                if (document.querySelector('#fundoSplashScreen').style.display !== 'none') {
                    detectionsFunc('roadDetections')
                }
                setTimeout(() => {
                    if (document.querySelector('#fundoSplashScreen').style.display !== 'none') {
                        detectionsFunc('roadDetections')
                    }
                    setTimeout(() => {
                        if (document.querySelector('#fundoSplashScreen').style.display !== 'none') {
                            detectionsFunc('roadDetections')
                        }
                        setTimeout(() => {
                            if (document.querySelector('#fundoSplashScreen').style.display !== 'none') {
                                location.reload()
                            }
                        }, 35000);
                    }, 35000);
                }, 35000);
            }, 35000);
        }, 35000);
    }, 35000);
}

function detectionsFunc(central) {
    var container = document.getElementById(central);
    var elements = container.querySelectorAll(':scope > *');
    let i = elements.length - 1
    for (let j = 0; j < elements.length; j++) {
        if (elements[j].classList[0] === "active") {
            elements[j].querySelector(':first-child').style.background = 'transparent'
        }
    }
    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    function setOpacity() {
        setTimeout(() => {
            if (elements[i].classList[0] === "active") {
                var detect = elements[i].querySelector(':first-child');
                detect.style.background = getRandomColor()
            }
            i--
            if (i < 0) {
                return
            }
            setOpacity()
        }, 650);
    }
    setTimeout(() => {
        setOpacity()
    }, 3000);
}

export function finishAnimation() {
    setTimeout(() => {
        document.querySelector('.splash').classList.add("finish")
        document.querySelector('.splash-text').classList.add("finish")
        setTimeout(() => {
            document.querySelector('.splash-text').style.display = "none"
        }, 3000)
        setTimeout(() => {
            getAllElements();
            StartSidebar()
            changeMonitoramentoHeight()
            setTimeout(() => {
                document.querySelectorAll(".chart-container").forEach(element => {
                    element.style.maxWidth = (window.screen.width / 3) + "px"
                    element.style.minWidth = (window.screen.width / 3) + "px"
                    // Remove min/max-width
                    setTimeout(() => {
                        element.style.maxWidth = '';
                        element.style.minWidth = '';
                    }, 200);
                });
                disableAllModes("Monitoramento")
                disableAllModes("Inventário")
                disableAllModes("Análise")
                const container = document.querySelectorAll(".toggleSessionsSub")
                setTimeout(() => {
                    container[0].getElementsByTagName("input")[0].click();
                    updateChartHeight()
                }, 1000);
            }, 10);
            document.body.style.cursor = "default"
        }, 1400)
        setTimeout(() => {
            document.querySelector('#fundoSplashScreen').style.display = "none"
        }, 2800)
    }, 500);
}

function getAllElements() {
    var container = document.getElementById('fundoSplashScreen');
    var elements = container.querySelectorAll('*');

    for (let i = 0; i < elements.length; i++) {
        if (i < 243) { elements[i].style.display = 'none' }
    }
}

export function SplashScreen() {
    function toggleLightness(event) {
        event.persist()
        event.target.classList.toggle('active')
    }

    return (
        <div id="fundoSplashScreen">
            <div className="sky">
                <div className="moon"></div>
                <div className="cloud"></div>
                <div className="cloud" style={{ top: '80px', left: '300px' }}></div>
                <div className="cloud" style={{ top: '20px', left: '600px' }}></div>
                <div className="star" style={{ right: '100px', top: '100px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ right: '200px', top: '210px' }}>
                    <div className="star2"></div>
                </div>
                <div className="star" style={{ right: '430px', top: '50px' }}>
                    <div className="star2"></div>
                </div>
                <div className="star" style={{ right: '140px', top: '220px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ right: '300px', top: '120px' }}>
                    <div className="star3"></div>
                </div>
                <div className="star" style={{ right: '500px', top: '130px' }}>
                    <div className="star2"></div>
                </div>
                <div className="star" style={{ right: '150px', top: '70px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ right: '190px', top: '240px' }}>
                    <div className="star3"></div>
                </div>
                <div className="star" style={{ right: '700px', top: '150px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ right: '150px', top: '250px' }}>
                    <div className="star2"></div>
                </div>
                <div className="star" style={{ right: '300px', top: '10px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ right: '400px', top: '160px' }}>
                    <div className="star3"></div>
                </div>
                <div className="star" style={{ left: '100px', top: '190px' }}>
                    <div className="star2"></div>
                </div>
                <div className="star" style={{ right: '300px', top: '270px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ left: '400px', top: '120px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ left: '150px', top: '280px' }}>
                    <div className="star2"></div>
                </div>
                <div className="star" style={{ left: '300px', top: '100px' }}>
                    <div className="star3"></div>
                </div>
                <div className="star" style={{ left: '430px', top: '190px' }}>
                    <div className="star3"></div>
                </div>
                <div className="star" style={{ left: '120px', top: '10px' }}>
                    <div className="star1"></div>
                </div>
                <div className="star" style={{ left: '500px', top: '210px' }}>
                    <div className="star2"></div>
                </div>
                <div className="star" style={{ left: '470px', top: '110px' }}>
                    <div className="star3"></div>
                </div>
                <div className="star" style={{ left: '600px', top: '200px' }}>
                    <div className="star1"></div>
                </div>
            </div>
            <div className="sidewalk"></div>
            <div className="road">
                <div id="roadDetections">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="buraco">
                            <div></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="faixaPedestre">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="bocaDeLobo">
                            <div></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="hidrante">
                            <div className="topo"></div>
                            <div className="braco"></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="buraco">
                            <div></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="lixeira">
                            <i className="fas fa-trash"></i>
                            <div></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="tampaDePV">
                            <div>◍</div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="hidrante">
                            <div className="topo"></div>
                            <div className="braco"></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className="active">
                        <div className="detect"></div>
                        <div className="bocaDeLobo">
                            <div></div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                </div>
                <div className="line"></div>
            </div>
            <div className="building" style={{ height: '380px', transform: 'translateX(100px)' }}>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
            </div>
            <div className="building" style={{ height: '200px', transform: 'translateX(280px)' }}>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
            </div>
            <div className="building" style={{ height: '550px', transform: 'translateX(460px)' }}>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
            </div>
            <div className="building" style={{ height: '250px', transform: 'translateX(-250px)' }}>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
            </div>
            <div className="building" style={{ height: '450px', transform: 'translateX(-430px)' }}>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
            </div>
            <div className="building" style={{ height: '150px', transform: 'translateX(-620px)' }}>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
            </div>
            <div className="building">
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
                <div onMouseEnter={(e) => toggleLightness(e)} className="active"></div>
                <div onMouseEnter={(e) => toggleLightness(e)}></div>
            </div>

            <div className="car">
                <div className="teto">
                    <div className="window">
                        <div></div>
                    </div>
                </div>
                <div className="wheel left">
                    <div></div>
                </div>
                <div className="wheel right">
                    <div></div>
                </div>
            </div>
            <div className="car2">
                <div className="teto2">
                    <div className="window">
                        <div style={{ backgroundColor: '#1714aa' }}></div>
                    </div>
                </div>
                <div className="wheel left">
                    <div></div>
                </div>
                <div className="wheel right">
                    <div></div>
                </div>
            </div>
            <div className="splash">
                <div className="loader"></div>
                <div className="splash-text">Rugosidade e Detecções</div>
            </div>
        </div>
    )
}