import React from "react";
import Carousel from "react-gallery-carousel";

export default function ExpandedImage(props) {
    const images = props.images.map(item => ({ src: item.url }));

    const closeExpandedImage = () => {
        document.getElementById("fundoExpandedImage").style.opacity = 0
        document.getElementById("fundoExpandedImage").style.pointerEvents = "none"
    }

    return (
        <div id="fundoExpandedImage">
            <div className="closeExpandedImage" role="button" tabIndex={0}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        closeExpandedImage
                    }
                }}
                onClick={closeExpandedImage}>
                x
            </div>
            <Carousel
                images={images}
                thumbnailWidth={'10%'}
                hasSizeButton={false}
                style={{ maxWidth: '50%', maxHeight: "90%" }}
            />
        </div>
    )
}