import React from "react";
import { RefreshMap } from "../../index";

var titleCompar;

export function OpenTitle(event, title) {
    document.getElementById("popupInfo").style.left = event.clientX + "px"
    document.getElementById("popupInfo").style.top = event.clientY + "px"
    document.getElementById("popupInfo").style.opacity = 1
    titleCompar = title
}

export function closeTitle() {
    RefreshMap()
    document.getElementById("popupInfo").style.opacity = 0
}

export function TitleInfo() {   
    return (
        <div id="popupInfo">
            {titleCompar}
        </div>
    )
}

// #popupInfo{
//     position: absolute;
//     opacity: 0;
//     max-width: 800px;
//     max-height: 200px;
//     background: #fff;
//     white-space: nowrap;
//     border: solid 1px #d4d4d4;
//     border-radius: 2px;
//     padding: 3px;
//     font-size: 14px;
//     z-index: 20000;
//     pointer-events: none;
//     transition: .2s;
// }