/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { filterBySize, fixOrientation2d, fixOrientation3d, zoomIn, zoomOut } from "../../index";
import { setOrientation } from "../../DataHandler/DataHandler.jsx";

var changeMap = false

export function firstFilter() {
    changeMap = true
}

let timer = 0
export function MapControl() {
    const deButton = document.getElementById('deButton');
    const ateButton = document.getElementById('ateButton');
    if (deButton !== null) {
        if (timer === 0) {
            timer++
            let isEnterHandled = false;
            deButton.addEventListener('keydown', function (event) {
                if (event.keyCode === 13 && !isEnterHandled) {
                    isEnterHandled = true;
                    filterBySize();
                }
            });

            ateButton.addEventListener('keydown', function (event) {
                if (event.keyCode === 13 && !isEnterHandled) {
                    isEnterHandled = true;
                    filterBySize();
                }
            });
            setTimeout(() => {
                isEnterHandled = false
                timer = 0
            }, 5000);
        }
    }

    const [icone, SetIcone] = useState("fal fa-globe")

    useEffect(() => {
        if (changeMap) {
            setOrientation(true)
            SetIcone("fal fa-map")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeMap])


    const callZoomOut = () => {
        zoomOut()
    }

    const callZoomIn = () => {
        zoomIn()
    }

    const callFixOrientation = () => {
        if (icone === "fal fa-globe") {
            setOrientation(true)
            SetIcone("fal fa-map")
            fixOrientation3d()
        } else {
            setOrientation(false)
            SetIcone("fal fa-globe")
            fixOrientation2d()
        }
    }

    return (
        <div id="zoomPanel" className="zoomPanel">
            <div onClick={callZoomOut} className="zoomControlMinus" title="Zoom Out">
                <div className="minusSymbol">-</div>
            </div>
            <div onClick={callFixOrientation} className="orientation" title="Ajustar visualização do mapa">
                <i className={icone}></i>
            </div>
            <div onClick={callZoomIn} className="zoomControlPlus" title="Zoom In">
                <div className="plusSymbol">+</div>
            </div>
        </div>
    )
}