import React, { useEffect, useState } from "react"
import { ChartModel } from "../.."
import axios from "axios"

export function ChartRug() {
    const [chartRug, setChartRug] = useState([])
    const [labelsRug, setLabelsRug] = useState([])

    useEffect(() => {
        axios.get("https://dev.geovista.com.br/api/ruas/js/recife/historico_qualidade.json").then((response) => {
            console.log('response.data', response.data)
            setChartRug(response.data.datas.map(subArray =>
                subArray.map(value => parseFloat(value))
            ))
            setLabelsRug(response.data.labels)
        })
    }, [])

    return (
        <div className="chart-container" >
            <ChartModel
                mode="bar"
                labels={labelsRug}
                datas={chartRug}
                colors={['#3b842f', '#88d07b', '#f7de6f', '#fea19e', '#7f312f']}
                responsive={true}
                stacked={true}
                legend={false}
                counter="m"
            />
        </div>
    )
}