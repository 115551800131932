/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import $ from "jquery"

export function rightButton(x, y) {
    document.getElementById("fundoMouseRight").style.display = "flex";
    document.getElementById("bordaCirculo").style.left = x - "70" + "px";
    document.getElementById("bordaCirculo").style.top = y - "70" + "px";
    document.getElementById("changeInfoMouseRight").innerHTML = "";
    $("#bordaCirculo").mouseleave(function () {
        document.getElementById("bordaCirculo").style.left = "-7000px";
    })
}

function funcao1() {
    console.log("Realizei a primeira função!!")
}
function funcao2() {
    console.log("Realizei a segunda função!!")
}
function funcao3() {
    console.log("Realizei a terceira função!!")
}
function funcao4() {
    console.log("Realizei a quarta função!!")
}

export function RightButton() {

    function changeNameMouseRight(compar) {
        if (compar === 1) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Função Nº 1";
        } else if (compar === 2) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Função Nº 2";
        } else if (compar === 3) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Função Nº 3";
        } else if (compar === 4) {
            document.getElementById("changeInfoMouseRight").innerHTML =
                "Função Nº 4";
        }
    }

    return (
        <div id="fundoMouseRight">
            <div id="bordaCirculo">
                <div id="clickMouseRight1" onMouseOver={() => changeNameMouseRight(1)} onClick={funcao1}>
                    <img src="https://www.iconpacks.net/icons/1/free-pin-icon-48-thumb.png" className="iconeMouseRight" />
                </div>
                <div id="clickMouseRight2" onMouseOver={() => changeNameMouseRight(2)} onClick={funcao2}>
                    <img src="https://www.iconpacks.net/icons/1/free-pin-icon-48-thumb.png" className="iconeMouseRight"
                        style={{ marginLeft: "18px" }} />
                </div>
                <div id="clickMouseRight3" onMouseOver={() => changeNameMouseRight(3)} onClick={funcao3}>
                    <img src="https://www.iconpacks.net/icons/1/free-pin-icon-48-thumb.png" className="iconeMouseRight"
                        style={{ marginTop: "20px" }} />
                </div>
                <div id="clickMouseRight4" onMouseOver={() => changeNameMouseRight(4)} onClick={funcao4}>
                    <img src="https://www.iconpacks.net/icons/1/free-pin-icon-48-thumb.png" className="iconeMouseRight"
                        style={{ marginTop: "20px", marginLeft: "18px" }} />
                </div>
                <div id="clickMouseRight5"></div>
                <div className="infoMouseRight">
                    <p id="changeInfoMouseRight" style={{ fontSize: "17px", fontWeight: "bold" }}></p>
                </div>
            </div>
        </div>
    )
}