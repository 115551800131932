import { getDateFile, getModeMap } from "../../DataHandler/DataHandler.jsx";

function countQualidadeFeatures(geojson, name, mode) {
    const counter = {};
    geojson.features.forEach(feature => {
        const dataName = feature.properties[name];
        if (dataName !== 'Não percorrido') {
            // eslint-disable-next-line no-prototype-builtins
            if (counter.hasOwnProperty(dataName)) {
                counter[dataName]++;
            } else {
                counter[dataName] = 1;
            }
        }
    });
    const result = Object.entries(counter).map(([dataName, count]) => [dataName, count + mode]);
    return result;
}

function getOnTheList(data, list) {
    for (let i = 0; i < data.length; i++) { list.push(data[i]) }
    return list
}

export function downloadCsv(json, ia) {
    let rows = [["Identificação", "Detecção"], ["", ""]];

    if (getModeMap() === "rugosidade ia") { rows = [["Identificação", "Detecção"], ["", ""], ["Rugosidade", ""], ["", ""]] }
    json = countQualidadeFeatures(json, 'qualidade', 'km');
    ia = countQualidadeFeatures(ia, 'ai_object_name', ' detecções');

    rows = getOnTheList(json, rows)
    if (getModeMap() === "rugosidade ia") { rows.push(["", ""]); rows.push(["Inteligência Artificial", ""]); rows.push(["", ""]); }
    rows = getOnTheList(ia, rows)

    let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `rugosidade_${getDateFile()}.csv`);
    document.body.appendChild(link);
    link.click();
}
