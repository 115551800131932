import { getOpacidade } from "../../DataHandler/DataHandler.jsx";

// Variáveis necessárias na Legenda do mapa

var RGBcolor;

// Variáveis necessárias para controle de JSON

export function prepareDataIA(IA, separator) {
    return new Promise(resolve => {
        for (let i = 0; i < IA.features.length; i++) {
            for (let j = 0; j < separator.length; j++) {
                if (IA.features[i].properties.ai_object_id === separator[j].ai_object_id) {
                    Object.assign(IA.features[i].properties, separator[j]);
                }
            }
            IA.features[i].properties.visibility = true
            IA.features[i].properties.legend = true
            if (IA.features[i].properties.deteccao_ativa === true) {
                IA.features[i].properties.ai_object_name = IA.features[i].properties.ai_object_name + " - Ativo"
            } else if (IA.features[i].properties.deteccao_ativa === false) {
                IA.features[i].properties.ai_object_name = IA.features[i].properties.ai_object_name + " - Encerrado"
            }
        }
        resolve(IA)
    })
}

export function prepareColors(cores, opacidade) {
    const removeSymbol = cores.split('#')
    hexToRgb(removeSymbol[1])
    function hexToRgb(hex) {
        const arrBuff = new ArrayBuffer(4);
        const vw = new DataView(arrBuff);
        vw.setUint32(0, parseInt(hex, 16), false);
        const arrByte = new Uint8Array(arrBuff);
        if (getOpacidade() === false) {
            RGBcolor = [arrByte[1], arrByte[2], arrByte[3]]
        } else {
            if (opacidade !== undefined) {
                RGBcolor = [arrByte[1], arrByte[2], arrByte[3]]
            } else {
                RGBcolor = [arrByte[1], arrByte[2], arrByte[3], 70]
            }
        }
    }
    return RGBcolor
}

export function generateListNames(geojsons) {
    const list = []
    for (let i = 0; i < geojsons.length; i++) {
        const auxList = []
        for (let j = 0; j < geojsons[i].features.length; j++) {
            auxList.push(geojsons[i].features[j].properties.nome.toUpperCase())
        }
        list.push(auxList)
    }
    return list
}

