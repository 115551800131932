export function WorkerTime() {
    self.onmessage = (message) => {
        function distributeAlgorithms(json, property) {
            let vetorAlgoritmo = []
            let vetorGEOJSON = []
            for (let i = 0, len = json.features.length; i < len; i++) {
                const searchedProperties = json.features[i].properties[property]
                if (!vetorAlgoritmo.includes(searchedProperties)) {
                    const geojson = {
                        "name": searchedProperties,
                        "type": "FeatureCollection",
                        "features": []
                    }
                    vetorAlgoritmo.push(searchedProperties)
                    vetorGEOJSON.push(geojson)
                }
                const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
                vetorGEOJSON[indiceAlgoritmo].features.push(json.features[i])
            }
            vetorGEOJSON = sortArrayByQuality(vetorGEOJSON)

            return vetorGEOJSON
        }

        function sortArrayByQuality(json) {
            let AUXjson = []
            let AUXcondition
            for (let i = 0; i < json.length; i++) {
                for (let j = 0; j < json[i].features.length; j++) {
                    AUXcondition = json[i]
                    if (AUXcondition.name === "Ótimo") {
                        AUXjson[0] = AUXcondition
                    } else if (AUXcondition.name === "Bom") {
                        AUXjson[1] = AUXcondition
                    } else if (AUXcondition.name === "Regular") {
                        AUXjson[2] = AUXcondition
                    } else if (AUXcondition.name === "Ruim") {
                        AUXjson[3] = AUXcondition
                    } else if (AUXcondition.name === "Péssimo") {
                        AUXjson[4] = AUXcondition
                    }
                }
            }
            return AUXjson
        }

        function selectMap(json) {
            for (let i = 0; i < json.features.length; i++) {
                if (json.features[i].properties[getidentifier] === getIdentifierId) {
                    selectedStreet.features.push(json.features[i])
                }
            }
        }

        function prepareData(jsonData) {
            for (let i = 0; i < jsonData.features.length; i++) {
                jsonData.features[i].properties.visibility = true
                jsonData.features[i].properties.legend = true
                if (jsonData.features[i].properties[cor] === 1) {
                    jsonData.features[i].properties[tituloLegenda] = "Ótimo"
                    jsonData.features[i].properties[hexColorJson] = "#3B842F"
                    jsonData.features[i].properties.idLegenda = 0
                }
                else if (jsonData.features[i].properties[cor] === 2) {
                    jsonData.features[i].properties[tituloLegenda] = "Bom"
                    jsonData.features[i].properties[hexColorJson] = "#88D07B"
                    jsonData.features[i].properties.idLegenda = 1
                }
                else if (jsonData.features[i].properties[cor] === 3) {
                    jsonData.features[i].properties[tituloLegenda] = "Regular"
                    jsonData.features[i].properties[hexColorJson] = "#F7DE6F"
                    jsonData.features[i].properties.idLegenda = 2
                }
                else if (jsonData.features[i].properties[cor] === 4) {
                    jsonData.features[i].properties[tituloLegenda] = "Ruim"
                    jsonData.features[i].properties[hexColorJson] = "#FEA19E"
                    jsonData.features[i].properties.idLegenda = 3
                }
                else if (jsonData.features[i].properties[cor] === 5) {
                    jsonData.features[i].properties[tituloLegenda] = "Péssimo"
                    jsonData.features[i].properties[hexColorJson] = "#7F312F"
                    jsonData.features[i].properties.idLegenda = 4
                }
                else {
                    jsonData.features[i].properties[tituloLegenda] = "Não percorrido"
                    jsonData.features[i].properties[hexColorJson] = "#ffffff"
                    jsonData.features[i].properties.idLegenda = 5
                }
            }
            return jsonData
        }
        const hexColorJson = "hexCor"
        const cor = "cor"
        const dinamicos = message.data[0]
        const fixos = message.data[1]
        const getIdentifierId = message.data[2]
        const getOpacidade = message.data[3]
        const getidentifier = message.data[4]
        const tituloLegenda = message.data[5]
        const selectedStreet = {
            "name": "layer",
            "type": "FeatureCollection",
            "features": []
        }
        let fixosAux = JSON.parse(JSON.stringify(fixos));
        function mergeData(dinamicos, fixos) {
            fixos.features.forEach(feature => {
                const match = dinamicos.find(item => item.id_trecho === feature.properties.id_trecho);

                if (match) {
                    feature.properties = {
                        ...feature.properties,
                        ...match
                    };
                }
            });

            return fixos;
        }
        const mergedGeojson = mergeData(dinamicos, fixosAux);
        prepareData(mergedGeojson)
        if (getOpacidade === true) {
            selectMap(fixosAux)
        }

        let DATA = distributeAlgorithms(fixosAux, tituloLegenda)
        let DATAAUX = distributeAlgorithms(selectedStreet, tituloLegenda)
        let returnMessage = []
        returnMessage.push(fixosAux)
        returnMessage.push(selectedStreet)
        returnMessage.push(DATA)
        returnMessage.push(DATAAUX)
        postMessage(returnMessage);
    };
}

export function WorkerMain() {
    self.onmessage = (message) => {
        function distributeAlgorithms(json, property) {
            let vetorAlgoritmo = []
            let vetorGEOJSON = []
            for (let i = 0, len = json.features.length; i < len; i++) {
                const searchedProperties = json.features[i].properties[property]
                if (!vetorAlgoritmo.includes(searchedProperties)) {
                    const geojson = {
                        "name": searchedProperties,
                        "type": "FeatureCollection",
                        "features": []
                    }
                    vetorAlgoritmo.push(searchedProperties)
                    vetorGEOJSON.push(geojson)
                }
                const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
                vetorGEOJSON[indiceAlgoritmo].features.push(json.features[i])
            }
            vetorGEOJSON = sortArrayByQuality(vetorGEOJSON)

            return vetorGEOJSON
        }

        function sortArrayByQuality(json) {
            let AUXjson = []
            let AUXcondition
            for (let i = 0; i < json.length; i++) {
                for (let j = 0; j < json[i].features.length; j++) {
                    AUXcondition = json[i]
                    if (AUXcondition.name === "Ótimo") {
                        AUXjson[0] = AUXcondition
                    } else if (AUXcondition.name === "Bom") {
                        AUXjson[1] = AUXcondition
                    } else if (AUXcondition.name === "Regular") {
                        AUXjson[2] = AUXcondition
                    } else if (AUXcondition.name === "Ruim") {
                        AUXjson[3] = AUXcondition
                    } else if (AUXcondition.name === "Péssimo") {
                        AUXjson[4] = AUXcondition
                    }
                }
            }
            return AUXjson
        }
        function prepareData(jsonData) {
            for (let i = 0; i < jsonData.features.length; i++) {
                jsonData.features[i].properties.visibility = true
                jsonData.features[i].properties.legend = true
                if (jsonData.features[i].properties[cor] === 1) {
                    jsonData.features[i].properties[tituloLegenda] = "Ótimo"
                    jsonData.features[i].properties[hexColorJson] = "#3B842F"
                    jsonData.features[i].properties.idLegenda = 0
                } else if (jsonData.features[i].properties[cor] === 2) {
                    jsonData.features[i].properties[tituloLegenda] = "Bom"
                    jsonData.features[i].properties[hexColorJson] = "#88D07B"
                    jsonData.features[i].properties.idLegenda = 1
                } else if (jsonData.features[i].properties[cor] === 3) {
                    jsonData.features[i].properties[tituloLegenda] = "Regular"
                    jsonData.features[i].properties[hexColorJson] = "#F7DE6F"
                    jsonData.features[i].properties.idLegenda = 2
                } else if (jsonData.features[i].properties[cor] === 4) {
                    jsonData.features[i].properties[tituloLegenda] = "Ruim"
                    jsonData.features[i].properties[hexColorJson] = "#FEA19E"
                    jsonData.features[i].properties.idLegenda = 3
                } else if (jsonData.features[i].properties[cor] === 5) {
                    jsonData.features[i].properties[tituloLegenda] = "Péssimo"
                    jsonData.features[i].properties[hexColorJson] = "#7F312F"
                    jsonData.features[i].properties.idLegenda = 4
                } else {
                    jsonData.features[i].properties[tituloLegenda] = "Não percorrido"
                    jsonData.features[i].properties[hexColorJson] = "#ffffff"
                    jsonData.features[i].properties.idLegenda = 5
                }
            }
            return jsonData
        }

        const hexColorJson = "hexCor"
        const cor = "cor"
        const dinamicos = message.data[0]
        const fixos = message.data[1]
        const tituloLegenda = message.data[2]
        let fixosAux = []
        let returnMessage = []
        let DATA = []
        function mergeData(dinamicos, fixos) {
            fixos.features.forEach(feature => {
                const match = dinamicos.find(item => item.id_trecho === feature.properties.id_trecho);

                if (match) {
                    feature.properties = {
                        ...feature.properties,
                        ...match
                    };
                }
            });

            return fixos;
        }
        if (fixos !== undefined) {
            fixosAux = JSON.parse(JSON.stringify(fixos));

            const mergedGeojson = mergeData(dinamicos, fixosAux);
            prepareData(mergedGeojson)
            DATA = distributeAlgorithms(fixosAux, tituloLegenda)
        }
        returnMessage.push(fixosAux)
        returnMessage.push(DATA)
        postMessage(returnMessage);
    };
}

