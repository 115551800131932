import $ from "jquery";

document.addEventListener('contextmenu', event => event.preventDefault());

export function distributeAlgorithmsFeatures(json, property) {
  let vetorAlgoritmo = []
  let vetorGEOJSON = []
  for (let i = 0, len = json.length; i < len; i++) {
    const searchedProperties = json[i].properties[property]
    if (!vetorAlgoritmo.includes(searchedProperties)) {
      const geojson = {
        "name": searchedProperties,
        "type": "FeatureCollection",
        "features": []
      }
      vetorAlgoritmo.push(searchedProperties)
      vetorGEOJSON.push(geojson)
    }
    const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
    vetorGEOJSON[indiceAlgoritmo].features.push(json[i])
  }
  return vetorGEOJSON
}

export function distributeAlgorithmsIA(json, property) {
  let vetorAlgoritmo = []
  let vetorGEOJSON = []
  for (let i = 0, len = json.features.length; i < len; i++) {
    const searchedProperties = json.features[i].properties[property]
    if (!vetorAlgoritmo.includes(searchedProperties)) {
      const geojson = {
        "name": searchedProperties,
        "type": "FeatureCollection",
        "features": []
      }
      vetorAlgoritmo.push(searchedProperties)
      vetorGEOJSON.push(geojson)
    }
    const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
    vetorGEOJSON[indiceAlgoritmo].features.push(json.features[i])
  }
  return vetorGEOJSON
}

export function distributeAlgorithms(json, property) {
  let vetorAlgoritmo = []
  let vetorGEOJSON = []
  for (let i = 0; i < json.features.length; i++) {
    const searchedProperties = json.features[i].properties[property]
    if (!vetorAlgoritmo.includes(searchedProperties)) {
      const geojson = {
        "name": searchedProperties,
        "type": "FeatureCollection",
        "features": []
      }
      vetorAlgoritmo.push(searchedProperties)
      vetorGEOJSON.push(geojson)
    }
    const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
    vetorGEOJSON[indiceAlgoritmo].features.push(json.features[i])
  }
  vetorGEOJSON = sortArrayByQuality(vetorGEOJSON)

  return vetorGEOJSON
}

export function distributeAlgorithmsDistributed(json, property) {
  let vetorAlgoritmo = []
  let vetorGEOJSON = []
  for (let i = 0; i < json.length; i++) {
    for (let j = 0; j < json[i].features.length; j++) {
      const searchedProperties = json[i].features[j].properties[property]
      if (!vetorAlgoritmo.includes(searchedProperties)) {
        const geojson = {
          "name": searchedProperties,
          "type": "FeatureCollection",
          "features": []
        }
        vetorAlgoritmo.push(searchedProperties)
        vetorGEOJSON.push(geojson)
      }
      const indiceAlgoritmo = vetorAlgoritmo.indexOf(searchedProperties)
      vetorGEOJSON[indiceAlgoritmo].features.push(json[i].features[j])
    }
  }
  return vetorGEOJSON
}

function sortArrayByQuality(json) {
  let AUXjson = []
  let AUXcondition
  for (let i = 0; i < json.length; i++) {
    for (let j = 0; j < json[i].features.length; j++) {
      AUXcondition = json[i]
      if (AUXcondition.name === "Ótimo") {
        AUXjson[0] = AUXcondition
      } else if (AUXcondition.name === "Bom") {
        AUXjson[1] = AUXcondition
      } else if (AUXcondition.name === "Regular") {
        AUXjson[2] = AUXcondition
      } else if (AUXcondition.name === "Ruim") {
        AUXjson[3] = AUXcondition
      } else if (AUXcondition.name === "Péssimo") {
        AUXjson[4] = AUXcondition
      }
    }
  }
  AUXjson = $.grep(AUXjson, function (n) { return n == 0 || n });

  return AUXjson
}